import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioModel } from '../models/usuario.model';
import { map } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private url = 'https://identitytoolkit.googleapis.com/v1';
  private url2 = 'https://basededatosghm.firebaseio.com';
 private apikey = 'AIzaSyCFNHNk_u8r2wymzdjAFAZsPUEIL8cTl1Y';

  userToken: string;
  

// Crear nuevo usuario
// https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=[API_KEY]

// Login
// https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=[API_KEY]


  constructor( private http: HttpClient, private afs: AngularFirestore) {
    this.leerToken();
   }

  logout() {

  localStorage.removeItem('token');
  localStorage.removeItem('expira');

  }

  login( usuario: UsuarioModel) {

    const authData = {
      ...usuario,
      returnSecureToken: true
    };

    return this.http.post(
      `${ this.url }/accounts:signInWithPassword?key=${ this.apikey }`,
      authData
    ).pipe(
      map( resp => {

         this.guardarToken( resp['idToken'] );
         return resp;

      })
     );
  }

  nuevoUsuario( usuario: UsuarioModel) {

    const authData = {
      ...usuario,
      returnSecureToken: true
    };

    return this.http.post(
      `${ this.url }/accounts:signUp?key=${ this.apikey }`,
      authData
    ).pipe(
     map( resp => {

        this.guardarToken( resp['idToken'] );
        
        return resp;

     })
    );
  }
 


  private guardarToken( idToken: string ) {

    this.userToken = idToken;
    localStorage.setItem('token', idToken);

    let hoy = new Date();
    hoy.setSeconds( 3600 );
    localStorage.setItem('expira', hoy.getTime().toString() );

  }

  leerToken() {

    if ( localStorage.getItem('token') ) {

      this.userToken = localStorage.getItem('token');

    } else {
      this.userToken = '';
    }

    return this.userToken;


  }

  estaAutenticado(): boolean {

    if ( this.userToken.length < 2 ) {
      return false;
    }

    const expira = Number(localStorage.getItem('expira'));
    const expiraDate = new Date();
    expiraDate.setTime(expira);

    if ( expiraDate > new Date()) {
      return true;
    } else {
      return false;
    }

  }

  public isAdmin(): boolean {
    let isAdmin = false;
    let user = this.getUserToken();
    if (user !== null && user === ('jorgis_sg@hotmail.com') ) {
      isAdmin = true;
    } else {
      isAdmin = false;
    }
    return isAdmin;
}

public getUserToken(): any {
    return localStorage.getItem('email');
}

}
