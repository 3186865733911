

export class PerfilModel{

    id: string;
    email: string;
    rol: string;
    rutina: string;
    dieta: string;

    constructor(){
  
    }
    }
