import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DietasSService } from '../../services/dietas-s.service';
import { DietaModel } from '../../models/dieta.model';
import { AuthService } from '../../services/auth.service';
import { PerfilesSService } from '../../services/perfiles-s.service';
import { PerfilModel } from '../../models/perfil.model';

@Component({
  selector: 'app-dietas',
  templateUrl: './dietas.component.html',

})
export class DietasComponent implements OnInit {
  dietas: DietaModel[] = [];
  perfiles : PerfilModel [] = [];
  rol : string;
  public isAdmin: boolean = false;


  constructor(private DietasSService: DietasSService , private _perfilesService: PerfilesSService, private router: Router, private auth: AuthService) {



   }

  ngOnInit(): void {
    this.isAdmin = this.auth.isAdmin();
    this.DietasSService.getDietas().toPromise().then((data: DietaModel[]) => {
      this.dietas = data;
      this.dietas.forEach(element => {
        element.imagen="https://firebasestorage.googleapis.com/v0/b/basededatosghm.appspot.com/o/C%3A%5Cfakepath%5C"+element.imagen.substr(12)+"?alt=media&token=f6af9e5c-6c5e-467c-8c72-0ec3227463ae";
      });
    }, err => {
      console.error(err);
    });

    this._perfilesService.getPerfiles().toPromise().then((data: PerfilModel[]) => {
      this.perfiles = data;
      this.perfiles.forEach(element => {
        if(element.email == localStorage.getItem('id')){
          this.rol=element.rol;
        }
      });
    }, err => {
      console.error(err);
    });
    }



buscarDieta(termino: string){
  this.router.navigate(['/buscard', termino]);
}

}
