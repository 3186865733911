<div class="col-md-8">
    <h1 class="animated fadeIn fast">Identificarse <small>en la web</small></h1>
</div>
<hr>
<div class="limiter animated fadeIn">
    <div class="container-login100">
        <div class="wrap-login100 p-t-50 p-b-90">
            <form (ngSubmit)="onSubmit(f)" #f="ngForm" class="login100-form validate-form flex-sb flex-w">

                <span class="login100-form-title p-b-51">
                    Login
                </span>

                <span *ngIf="f.submitted && f.controls['email'].errors" class="text-danger animated fadeIn">Correo electrónico incorrecto</span>
                <div class="wrap-input100 m-b-16">
                    <input class="input100" type="email" name="email" [(ngModel)]="usuario.email" required email placeholder="Email">

                    <span class="focus-input100"></span>
                </div>

                <span *ngIf="f.submitted && f.controls['password'].errors" class="text-danger">Contraseña incorrecta</span>
                <div class="wrap-input100 m-b-16" data-validate="Password is required">
                    <input class="input100" type="password" name="password" required minlength="6" [(ngModel)]="usuario.password" placeholder="Password">
                    <span class="focus-input100"></span>
                </div>

                <div class="flex-sb-m w-full p-t-3 p-b-24">
                    <div class="contact100-form-checkbox">
                        <input [(ngModel)]="recordarme"
                        class="input-checkbox100" id="ckb1" type="checkbox" name="remember-me">
                        <label class="label-checkbox100" for="ckb1">
                            Recordar mi usuario
                        </label>
                    </div>

                    <div>
                        <a routerLink="/registro" class="txt1">
                            ¿No tienes cuenta?
                        </a>
                    </div>
                </div>

                <div class="container-login100-form-btn m-t-17">
                    <button class="login100-form-btn" type="submit">
                        Ingresar
                    </button>
                </div>

            </form>
        </div>
    </div>
</div>