<div class="row animated fadeIn">

    <div class="col-md-8">
        <h1 class="animated fadeIn fast">Buscando: <small>{{termino}}</small></h1>
    </div>

    <div class="col-md-4">
        <div class="form-inline my-2 my-lg-0" style="padding-top: 10px;">
            <input class="form-control mr-sm-2" type="text" placeholder="Buscar dieta" (keyup.enter)="buscarDieta(buscarTexto.value)" #buscarTexto>
            <button (click)="buscarDieta(buscarTexto.value)" class="btn btn-outline-dark btn-out my-2 my-sm-0" type="button">Buscar</button>
        </div>
    </div>

</div>
<hr>

<div class="row animated fadeIn" *ngIf="dietas.length == 0">
    <div class="col-md-12">
        <div class="alert alert-info animated fadeIn" role="alert">
            No existen resultados con el término: {{termino}}
        </div>
    </div>
</div>


<div class="card-columns animated fadeIn">

    <div class="card animated fadeIn fast" *ngFor="let dieta of dietas; let i=index">
        <img [src]="dieta.imagen" width="300" height="250" class="card-img-top" [alt]="dieta.nombre">
        <div class="card-body">
            <h5 class="card-title">{{dieta.nombre}}</h5>
            <p class="card-text">{{dieta.descripcion | slice:0:50}}...</p>
            <button [routerLink]="['/dieta',dieta.id]" type="button" class="btn btn-outline-primary btn-block">Ver más...</button>
        </div>

    </div>
</div>