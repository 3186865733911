<h1>Añadir <small>Rutina</small></h1>
<hr>
<!-- The core Firebase JS SDK is always required and must be listed first -->
<script src="https://www.gstatic.com/firebasejs/7.14.5/firebase-app.js"></script>

<!-- TODO: Add SDKs for Firebase products that you want to use
     https://firebase.google.com/docs/web/setup#available-libraries -->
<script src="https://www.gstatic.com/firebasejs/7.14.5/firebase-analytics.js"></script>



<div class="row">
  <div class="col">
    <form (ngSubmit)="guardar( f )"
    
    #f="ngForm">
      <div class="form-group">
        <label>Firebase ID</label>
        <input
          type="text"
          class="form-control"
          placeholder="Firebase ID"
          [(ngModel)]="rutina.id"
          name="id"
          disabled="disable">
        <small class="form-text text-muted">Este campo es autogenerado</small>
      </div>

      <div class="form-group">
        <label>Nombre de Rutina</label>
        <input
          type="text"
          class="form-control"
          placeholder="Nombre de rutina"
          [(ngModel)]="rutina.nombre"
          name="nombre"
          required>
      </div>
      <br>

      <div class="form-group">
        <label>Descripcion de Rutina</label>
        <input
          type="text"
          class="form-control"
          placeholder="Descripcion de rutina"
          [(ngModel)]="rutina.descripcion"
          name="descripcion"
          required>
      </div>
      <br>

      <div class="form-group">
        <label>Foto de la Rutina</label>
        <input
          type="file"
          class="form-control"
          placeholder="Foto de la rutina"
          [(ngModel)]="rutina.imagen"
          name="imagen"
          (change)="upload($event)"
          >
      </div>

      <hr>

      <div class="form-group text-center">
        <button type="submit" class="btn btn-primary w-25" >
          <i class="fa fa-save"></i>
          Añadir
        </button>
      </div>
    </form>
    <br>
  </div>
</div>

<div class="row text-right">
  <div class="col">
    <button routerLink="/rutinas" class="btn btn-outline-danger btn-block">
      <i class="fa fa-arrow-left"></i>
      Volver al menú
    </button>
  </div>
</div>
<script>
  // Your web app's Firebase configuration
  var firebaseConfig = {
    apiKey: "AIzaSyCFNHNk_u8r2wymzdjAFAZsPUEIL8cTl1Y",
    authDomain: "basededatosghm.firebaseapp.com",
    databaseURL: "https://basededatosghm.firebaseio.com",
    projectId: "basededatosghm",
    storageBucket: "basededatosghm.appspot.com",
    messagingSenderId: "165946587833",
    appId: "1:165946587833:web:c153c573739d1e95bd7968",
    measurementId: "G-HLDDJLE95W"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
</script>
