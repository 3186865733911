<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand" href="#">GymHealthManager</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">

            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['home']">Inicio</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['rutinas']">Rutinas</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['dietas']">Dietas</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['about']">Sobre nosotros</a>
            </li>

        </ul>

        <div class="nav-item dropdown">
            <div>
                <button class="btn btn-outline-primary" [routerLink]="['/perfil']">Mi cuenta</button>
                <span style="padding-left: 25px;"></span>
                <button class="btn btn-outline-danger" (click)="salir()">Cerrar Sesión</button>
            </div>




        </div>

    </div>
</nav>