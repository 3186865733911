<h1 class="animated fadeIn fast">{{rutina.nombre | uppercase}} <small>rutina de entrenamiento</small></h1>
<hr>
<div class="row animated fadeIn">
    <div class="col-md-4">
        <img class="img-fluid" [src]="rutina.imagen" [alt]="rutina.nombre">
        <br><br>
        <a (click)="agregarRutina(rutina)" class="btn btn-outline-success btn-block">Seleccionar rutina</a>
        <a *ngIf="this.rol=='1'" (click)="borrarRutina(rutina)" class="btn btn-outline-danger btn-block" style="color: red;">Borrar rutina</a>
        <a [routerLink]="['/rutinas']" class="btn btn-outline-primary btn-block" style="color: blue;">Ver todas las rutinas</a>

        <br><br><br><br><br><br><br><br><br><br><br><br><br>
    </div>

    <div class="col-md-8">
        <h3>{{rutina.nombre}}</h3>
        <hr>
        <p>
            {{rutina.descripcion}}
        </p>

    </div>
    <div *ngIf="this.rol=='1'" class="row">
        <div class="col text-right">
            <button [routerLink]="['/actualizar',rutina.id]" class="btn btn-primary">
                <i class="fa fa-plus"></i>
                Modificar rutina
            </button>
        </div>
    </div>

</div>