import { Component, OnInit } from '@angular/core';
import { UsuarioModel } from '../../models/usuario.model';
import { NgForm } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { PerfilModel } from '../../models/perfil.model';
import { RutinaModel } from '../../models/rutina.model';
import { Injectable } from '@angular/core';
import { RutinasSService } from '../../services/rutinas-s.service';
import { Observable } from 'rxjs';
import { PerfilesSService } from 'src/app/services/perfiles-s.service';




@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./main.css', './util.css']
})
export class RegistroComponent implements OnInit {
  private url = 'https://basededatosghm.firebaseio.com';
  usuario: UsuarioModel;
  perfil = new PerfilModel();
  recordarme = false;
  

  constructor( private auth: AuthService,
               private router: Router,
               private http: HttpClient,
               private perfilesService: PerfilesSService) { }

  ngOnInit(): void {
    this.usuario = new UsuarioModel();

  }
onSubmit(form: NgForm){
  if ( form.invalid ) { return; }

  Swal.fire({
    allowOutsideClick: false,
    text: 'Espere por favor...',
    icon: 'info'
  });
  Swal.showLoading();
 
  this.auth.nuevoUsuario( this.usuario )
  .subscribe( resp => {

    console.log(resp);
    Swal.close();
    localStorage.setItem('id', this.usuario.email);
    if ( this.recordarme ) {
      localStorage.setItem('email', this.usuario.email);
    }
    let peticion: Observable<any>;

    this.perfil.email=this.usuario.email;
    this.perfil.rol="2";
     peticion = this.perfilesService.crearPerfil( this.perfil );

    

    peticion.subscribe( resp => {

    });
  
    this.router.navigateByUrl('/home');

  }, (err) => {
    console.log(err.error.error.message);
    Swal.fire({
      text: err.error.error.message,
      icon: 'error',
      title: 'Error al registrarse'
    });
  });
}


}
