import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RutinasSService } from '../../services/rutinas-s.service';

@Component({
  selector: 'app-buscador',
  templateUrl: './buscador.component.html'
})
export class BuscadorComponent implements OnInit {

  rutinas: any[] = [];
  termino : string;
  constructor(private activatedRoute: ActivatedRoute,
              private _rutinasService: RutinasSService,private router:Router) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe( params =>{
      this.termino=params['termino'];
      this.rutinas = this._rutinasService.buscarRutinas(this.termino);

    });
  }
  verRutina(idx: number){
    this.router.navigate(['/rutina',idx]);
    }
    buscarRutina(termino: string){
      this.router.navigate(['/buscar',termino]);
    }

}
