<div class="row animated fadeIn">

    <div class="col-md-8">
        <h1 class="animated fadeIn fast">Mi Perfil <small> {{this.email}}</small></h1>
    </div>
</div>
<hr>
<div class="card-columns animated fadeIn">
    <div class="card animated fadeIn fast">
        <img *ngIf="rutina.imagen!=undefined" [src]="rutina.imagen" width="300" height="250" class="card-img-top">
        <div class="card-body">
            <h5 class="card-title">{{this.rutina.nombre}}</h5>
            <p class="card-text">{{this.rutina.descripcion | slice:0:50 }}</p>
            <button *ngIf="rutina.imagen!=undefined" [routerLink]="['/rutina',rutina.id]" type="button" class="btn btn-outline-primary btn-block">Ver más...</button>
        </div>
    </div>


    <div class="card animated fadeIn fast">
        <img *ngIf="dieta.imagen!=undefined" [src]="dieta.imagen" width="300" height="250" class="card-img-top">
        <div class="card-body">
            <h5 class="card-title">{{this.dieta.nombre}}</h5>
            <p class="card-text">{{this.dieta.descripcion | slice:0:50 }}</p>
            <button *ngIf="rutina.imagen!=undefined" [routerLink]="['/dieta',dieta.id]" type="button" class="btn btn-outline-primary btn-block">Ver más...</button>
        </div>
    </div>



</div>