<div class="row animated fadeIn">

    <div class="col-md-8">
        <h1 class="animated fadeIn fast">Rutinas <small>de entrenamiento</small></h1>
    </div>

    <div class="col-md-4">
        <div class="form-inline my-2 my-lg-0" style="padding-top: 10px;">
            <input class="form-control mr-sm-2" type="text" placeholder="Buscar rutina" (keyup.enter)="buscarRutina(buscarTexto.value)" #buscarTexto>
            <button (click)="buscarRutina(buscarTexto.value)" class="btn btn-outline-dark btn-out my-2 my-sm-0" type="button">Buscar</button>
        </div>
    </div>

</div>
<hr>


<div class="card-columns animated fadeIn">

    <div class="card animated fadeIn fast" *ngFor="let rutina of rutinas; let i=index">
        <img [src]="rutina.imagen" width="300" height="250" class="card-img-top">
        <div class="card-body">
            <h5 class="card-title">{{rutina.nombre}}</h5>
            <p class="card-text">{{rutina.descripcion | slice:0:50 }}...</p>
            <button [routerLink]="['/rutina',rutina.id]" type="button" class="btn btn-outline-primary btn-block">Ver más...</button>
        </div>

    </div>
</div>
<hr>
<div *ngIf="this.rol=='1'" class="row">
    <div class="col text-right">
        <button routerLink="/agregar/nuevo" class="btn btn-primary">
            <i class="fa fa-plus"></i>
            Añadir rutina
        </button>
    </div>
</div>