import { Component, OnInit } from '@angular/core';
import { DietaModel } from '../../models/dieta.model';
import { NgForm } from '@angular/forms';
import { DietasSService } from '../../services/dietas-s.service';

import { AngularFireStorage } from '@angular/fire/storage';
import 'firebase/storage';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-agregar2',
  templateUrl: './agregar2.component.html',
  styleUrls: ['./agregar2.component.css']
})
export class Agregar2Component implements OnInit {

  dieta = new DietaModel();

  uploadProgress: Observable<number>;

  uploadURL: Observable<string>;

  downloadURL = '';


  upload(event) {

    const file = event.target.files[0];
    const filepath = `${this.dieta.imagen}`;
    const fileRef = this.storage.ref(filepath);
    const task = this.storage.upload(filepath, file);


    task.snapshotChanges().pipe(
    finalize(() => {
    fileRef.getDownloadURL().subscribe(urlImage => {
    this.downloadURL = urlImage;
    console.log('URL_IMAGE', urlImage);
    });
    })
    ).subscribe();
    }

  constructor( private dietasService: DietasSService, private storage: AngularFireStorage) { }

  ngOnInit(): void {
  }
  guardar( form: NgForm){

    if ( form.invalid){
      console.log('Formulario no válido');
      return;
    }

    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      icon: 'info',
      allowOutsideClick: false
    });
    Swal.showLoading();

    let peticion: Observable<any>;

    if ( this.dieta.id ){

      peticion = this.dietasService.actualizarDieta( this.dieta );

    } else {

     peticion = this.dietasService.crearDieta( this.dieta );

    }

    peticion.subscribe( resp => {

      Swal.fire({
        title: this.dieta.nombre,
        text: 'Se creó correctamente',
        icon: 'success'
      });

    });


  }
}
