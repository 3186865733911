<div class="row animated fadeIn fast">
    <div class="col-md-8">
        <h1 class="animated fadeIn fast">Gym <small>Health Manager </small></h1>
    </div>

</div>
<hr>
<div class=" animated fadeIn col mb-12" width="100%">
    <div id="carouselExampleCaptions" class="carousel slide w-100" data-ride="carousel">
        <ol class="carousel-indicators">
            <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
            <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
            <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img src="assets/img/carrusel1.jpg" class="d-block w-100" alt="..." width="1000px" height="400px">
                <div class="carousel-caption d-none d-md-block">
                    <h5>Rutinas de entrenamiento</h5>
                    <p>Escoge entre una amplia selección de nuestras rutinas de entrenamiento y recuerda que siempre puedes consultar a nuestros profesionales para que te ayuden a elegir la mas adecuada para ti y tus objetivos
                    </p>
                </div>
            </div>
            <div class="carousel-item">
                <img src="assets/img/carrusel2.jpg" class="d-block w-100" alt="..." width="1000px" height="400px">
                <div class="carousel-caption d-none d-md-block">
                    <h5>Dietas</h5>
                    <p>Escoge entre una amplia selección de nuestras dietas y recuerda que siempre puedes consultar a nuestros profesionales para que te ayuden a elegir la mas adecuada para ti y tus objetivos
                    </p>
                </div>
            </div>
            <div class="carousel-item">
                <img src="assets/img/carrusel3.jpg" class="d-block w-100" alt="..." width="1000px" height="400px">
                <div class="carousel-caption d-none d-md-block">
                    <h5>Nuestros profesionales</h5>
                    <p>En nuestros profesionales encontrarás la ayuda que necesitas, cualquier duda o ayuda que necesites siempre estarán a tu disposición, ¡No dudes en consultarles!
                    </p>
                </div>
            </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
    </div>
</div>
<br><br><br><br>

<div class="row animated fadeIn">

    <div class="col mb-4">
        <div class="card">
            <img src="assets/img/novedad1.jpg" width="450px" height="375px" class="card-img-top" alt="Dani">
            <div class="card-body">
                <h5 class="card-title">Nuevas clases de boxeo</h5>
                <p class="card-text">Este mes empezarán las clases de boxeo, ¡Recuerda apuntarte en tu gimnasio!</p>
            </div>
        </div>
    </div>
    <div class="col mb-4">
        <div class="card">
            <img src="assets/img/novedad2.jpg" width="450px" height="375px" class="card-img-top" alt="Jorge">
            <div class="card-body">
                <h5 class="card-title">Nuevas clases de yoga</h5>
                <p class="card-text">Este mes empezarán las clases de yoga, ¡Recuerda apuntarte en tu gimnasio!</p>
            </div>
        </div>
    </div>
</div>