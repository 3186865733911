import { Component, OnInit } from '@angular/core';
import { PerfilesSService } from '../../services/perfiles-s.service';
import { PerfilModel } from '../../models/perfil.model';
import { RutinaModel } from 'src/app/models/rutina.model';
import { RutinasSService } from '../../services/rutinas-s.service';
import { DietasSService } from '../../services/dietas-s.service';
import { DietaModel } from 'src/app/models/dieta.model';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html'
})
export class PerfilComponent implements OnInit {
  public email: string;
  perfiles : PerfilModel [] = [];
  rol : string;
  rutina : RutinaModel= new RutinaModel();
  dieta : DietaModel= new DietaModel();
  constructor(private dietasService: DietasSService,private _perfilesService: PerfilesSService,private _rutinasService: RutinasSService ) { }

  ngOnInit(): void {
    this.rutina.nombre="No tienes ninguna rutina seleccionada";
    this.dieta.nombre="No tienes ninguna dieta seleccionada";
    this.email=localStorage.getItem('id');
    this._perfilesService.getPerfiles().toPromise().then((data: PerfilModel[]) => {
      this.perfiles = data;
      this.perfiles.forEach(element => {
        if(element.email == localStorage.getItem('id')){
          this.rol=element.rol;
          this._rutinasService.getRutina(element.rutina).toPromise().then((resp: RutinaModel)=>{
            if(resp!=null){
            this.rutina = resp;
            this.rutina.imagen="https://firebasestorage.googleapis.com/v0/b/basededatosghm.appspot.com/o/C%3A%5Cfakepath%5C"+this.rutina.imagen.substr(12)+"?alt=media&token=f6af9e5c-6c5e-467c-8c72-0ec3227463ae";
            this.rutina.id = element.rutina;
          }
            });
          



            this.dietasService.getDieta(element.dieta).toPromise().then((resp: DietaModel)=>{
              if(resp!=null){
              this.dieta = resp;
              this.dieta.imagen="https://firebasestorage.googleapis.com/v0/b/basededatosghm.appspot.com/o/C%3A%5Cfakepath%5C"+this.dieta.imagen.substr(12)+"?alt=media&token=f6af9e5c-6c5e-467c-8c72-0ec3227463ae";
              this.dieta.id = element.dieta;
              }
              });
            

        }
      });
    }, err => {
      console.error(err);
    });
  }

}
