import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DietasSService } from '../../services/dietas-s.service';
import { DietaModel } from 'src/app/models/dieta.model';
import { AuthService } from '../../services/auth.service';
import { PerfilesSService } from '../../services/perfiles-s.service';
import { PerfilModel } from '../../models/perfil.model';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dieta',
  templateUrl: './dieta.component.html',
  styles: [
  ]
})
export class DietaComponent implements OnInit {
  dieta: any = {};
  perfiles : PerfilModel [] = [];
  rol : string;
  public isAdmin: boolean = false;

  constructor(private activatedRoute: ActivatedRoute,
              private dietasService: DietasSService,
              private _perfilesService: PerfilesSService,
              private auth: AuthService,private router: Router) {

              this.activatedRoute.params.toPromise().then(params => {
                this.dieta = this.dietasService.getDieta(params['id']);
              });
            }

  ngOnInit(): void {
    this.isAdmin = this.auth.isAdmin();
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.dietasService.getDieta(id).toPromise().then((resp: DietaModel)=>{
      this.dieta = resp;
      this.dieta.imagen="https://firebasestorage.googleapis.com/v0/b/basededatosghm.appspot.com/o/C%3A%5Cfakepath%5C"+this.dieta.imagen.substr(12)+"?alt=media&token=f6af9e5c-6c5e-467c-8c72-0ec3227463ae";
      this.dieta.id = id;
      });
      this._perfilesService.getPerfiles().toPromise().then((data: PerfilModel[]) => {
        this.perfiles = data;
        this.perfiles.forEach(element => {
          if(element.email == localStorage.getItem('id')){
            this.rol=element.rol;
          }
        });
      }, err => {
        console.error(err);
      });
  }

  borrarDieta(dieta: DietaModel){
    this.dietasService.borrarDieta(dieta.id).toPromise().then();
    Swal.fire({
      title: 'Dieta borrada',
      text: '',
      icon: 'success'
    });
    this.router.navigateByUrl('/home');
  }


  agregarDieta(dieta: DietaModel){
    this._perfilesService.getPerfiles().toPromise().then((data: PerfilModel[]) => {
      this.perfiles = data;
      this.perfiles.forEach(element => {
        if(element.email == localStorage.getItem('id')){
          element.dieta=this.dieta.id;
          let peticion: Observable<any>;

          peticion =  this._perfilesService.actualizarPerfil( element );
      
          peticion.subscribe( resp => {
      
      
          });



        }
      });
    }, err => {
      console.error(err);
    });
    Swal.fire({
      title: 'Dieta seleccionada!',
      text: '',
      icon: 'success'
    });
    this.router.navigateByUrl('/dietas');
  }
}
