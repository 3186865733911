import { Injectable } from '@angular/core';
import { CanActivate, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

constructor( private auth: AuthService,
             private router: Router ){}


  canActivate() {


    if ( this.auth.estaAutenticado()) {
  return true;
} else {
  this.router.navigateByUrl('/registro');
  return false;
 }

  }

}
