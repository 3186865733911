import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RutinasSService } from '../../services/rutinas-s.service';
import { DietasSService } from '../../services/dietas-s.service';
@Component({
  selector: 'app-buscadord',
  templateUrl: './buscadord.component.html'
})
export class BuscadordComponent implements OnInit {

  dietas: any[] = [];
  termino : string;
  constructor(private activatedRoute: ActivatedRoute,private _dietasService: DietasSService,
             private router:Router) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe( params =>{
      this.termino=params['termino'];
      this.dietas = this._dietasService.buscarDietas( params['termino']);
    })
  }
  verDieta(idx: number){
    this.router.navigate(['/dieta',idx]);
    }
    buscarDieta(termino: string){
      this.router.navigate(['/buscard',termino]);
    }

}
