import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
})
export class NavbarComponent implements OnInit {
public email: string="asdasd";
  constructor( private auth: AuthService,
               private router: Router) { 

  
               }

  ngOnInit(): void {

     
    
    
   
  }

  salir() {
    if(localStorage.getItem('id')!=null){
    this.auth.logout();
    localStorage.removeItem('id');
    this.email='';
    Swal.fire({
      title: 'Sesion cerrada',
      text: '',
      icon: 'success'
    });
    this.router.navigateByUrl('/home');
  }
  else{
    Swal.fire({
      title: 'Error',
      text: 'No está logueado',
      icon: 'error'
    });
  }
    }

}
