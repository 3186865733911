

export class RutinaModel{


id: string;
nombre: string;
descripcion: string;
imagen: string;


constructor(){
}
}
