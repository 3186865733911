<div class="row animated fadeIn fast">

    <div class="col-md-8">
        <h1 class="animated fadeIn fast">Sobre <small>nosotros </small></h1>
    </div>

</div>
<hr>
<div class="row animated fadeIn">
    <div class="row row-cols-1 row-cols-md-2">
        <div class="col mb-4">
            <div class="card">
                <img src="assets/img/dani.jpg" width="450px" height="500px" class="card-img-top" alt="Dani">
                <div class="card-body">
                    <h5 class="card-title">Daniel María Recasens</h5>
                    <p class="card-text">Estudiante de Desarollo de Aplicaciones web en el IES Palomeras Vallecas. <br> Edad: 24 años</p>
                </div>
            </div>
        </div>
        <div class="col mb-4">
            <div class="card">
                <img src="assets/img/jorge.jpg" width="450px" height="500px" class="card-img-top" alt="Jorge">
                <div class="card-body">
                    <h5 class="card-title">Jorge Solano Gonzalez</h5>
                    <p class="card-text">Estudiante de Desarollo de Aplicaciones web en el IES Palomeras Vallecas. <br> Edad: 27 años</p>
                </div>
            </div>
        </div>
    </div>
</div>