import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { AngularFireStorage } from '@angular/fire/storage';
import 'firebase/storage';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/storage';
import * as firebase from 'firebase';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  public email: string="";
  constructor(private auth: AuthService,private router: Router) { 
    this.email=localStorage.getItem('id');
  }

  ngOnInit(): void {
    let user = firebase.auth().currentUser;
    if ( user != null) {

      console.log(user.uid);
      
    }
    
  }
  salir() {
    this.auth.logout();
    localStorage.removeItem('id');
    this.email='';
    this.router.navigateByUrl('/home');
    }
  

}
