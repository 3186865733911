import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PerfilModel } from '../models/perfil.model';


@Injectable({
  providedIn: 'root'
})
export class PerfilesSService {

  private url = 'https://basededatosghm.firebaseio.com';

  constructor( private http: HttpClient) { }

  crearPerfil( perfil: PerfilModel ){

    return this.http.post( `${ this.url }/perfiles.json`, perfil)
    .pipe(
      map( (resp: any) => {
        perfil.id = resp.name;
        return perfil;
      })
    );

  }

actualizarPerfil( perfil: PerfilModel ){

  const perfilTemp = {
    ...perfil
  };

  delete perfilTemp.id;
  return this.http.put(`${ this. url }/perfiles/${ perfil.id }.json`, perfilTemp);
 

}

getPerfiles() {
  return this.http.get(`${ this.url }/perfiles.json`)
  .pipe(
    map( this.crearArreglo )
  );
}
getPerfil(id: string) {

  return this.http.get(`${ this.url }/perfiles/${id}.json`)
}
borrarPerfil(id : string){
  return this.http.delete(`${ this.url }/perfiles/${id}.json`);
  

}
private crearArreglo( perfilesObj: object ){

  const perfiles: PerfilModel[] = [];

  if ( perfilesObj === null ) { return []; }

  Object.keys( perfilesObj ).forEach( key => {

    const perfil: PerfilModel = perfilesObj[key];
    perfil.id = key;
    perfiles.push( perfil );


  });

  return perfiles;

}}