import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RutinaModel } from '../models/rutina.model';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class RutinasSService {

  private url = 'https://basededatosghm.firebaseio.com';

  constructor( private http: HttpClient) { }

  crearRutina( rutina: RutinaModel ){

    return this.http.post( `${ this.url }/rutinas.json`, rutina)
    .pipe(
      map( (resp: any) => {
        rutina.id = resp.name;
        return rutina;
      })
    );

  }

actualizarRutina( rutina: RutinaModel ){

  const rutinaTemp = {
    ...rutina
  };

  delete rutinaTemp.id;
  console.log(rutina);
  console.log(rutinaTemp);
  return this.http.put(`${ this. url }/rutinas/${ rutina.id }.json`, rutinaTemp);
 

}

getRutinas() {
  return this.http.get(`${ this.url }/rutinas.json`)
  .pipe(
    map( this.crearArreglo )
  );
}
getRutina(id: string) {

  return this.http.get(`${ this.url }/rutinas/${id}.json`)
}
borrarRutina(id : string){
  return this.http.delete(`${ this.url }/rutinas/${id}.json`);
  

}
buscarRutinas(termino: string){
  let rutinas: RutinaModel[] = [];
  let rutinasArr: RutinaModel[] = [];
  this.getRutinas().toPromise().then((data: RutinaModel[]) => {
    rutinas = data;
    rutinas.forEach(element => {
      element.imagen="https://firebasestorage.googleapis.com/v0/b/basededatosghm.appspot.com/o/C%3A%5Cfakepath%5C"+element.imagen.substr(12)+"?alt=media&token=f6af9e5c-6c5e-467c-8c72-0ec3227463ae";
    });
    termino = termino.toLowerCase();
    for (let rutina of rutinas){
  let nombre= rutina.nombre.toLowerCase();
  if( nombre.indexOf(termino)>=0){
    rutinasArr.push(rutina);
  }
  }
  console.log(rutinasArr);
  }, err => {
    console.error(err);
  });

  return rutinasArr;
}
private crearArreglo( rutinasObj: object ){

  const rutinas: RutinaModel[] = [];

  if ( rutinasObj === null ) { return []; }

  Object.keys( rutinasObj ).forEach( key => {

    const rutina: RutinaModel = rutinasObj[key];
    rutina.id = key;
    rutinas.push( rutina );


  });

  return rutinas;

}}