import {RouterModule, Routes} from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { RutinasComponent } from './components/rutinas/rutinas.component';
import { AboutComponent } from './components/about/about.component';
import { DietasComponent } from './components/dietas/dietas.component';
import { ForoComponent } from './components/foro/foro.component';
import { RutinaComponent } from './components/rutina/rutina.component';
import { BuscadorComponent } from './components/buscador/buscador.component';
import { DietaComponent } from './components/dieta/dieta.component';
import { BuscadordComponent } from './components/buscadord/buscadord.component';
import { RegistroComponent } from './components/registro/registro.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { AgregarComponent } from './gestiones/agregar/agregar.component';
import { Agregar2Component } from './gestiones/agregar2/agregar2.component';
import { ActualizarComponent } from './gestiones/actualizar/actualizar.component';
import { Actualizar2Component } from './gestiones/actualizar2/actualizar2.component';
import { PerfilComponent } from './components/perfil/perfil.component';
import { NgModule } from '@angular/core';



const APP_ROUTES: Routes = [
{ path: 'home', component: HomeComponent},
{ path: 'login', component: LoginComponent},
{ path: 'perfil', component: PerfilComponent, canActivate: [ AuthGuard ]},
{ path: 'registro', component: RegistroComponent},
{ path: 'rutinas', component: RutinasComponent, canActivate: [ AuthGuard ]},
{ path: 'rutina/:id', component: RutinaComponent, canActivate: [ AuthGuard ]},
{ path: 'dieta/:id', component: DietaComponent, canActivate: [ AuthGuard ]},
{ path: 'buscar/:termino', component: BuscadorComponent},
{ path: 'buscard/:termino', component: BuscadordComponent},
{ path: 'dietas', component: DietasComponent, canActivate: [ AuthGuard ]},
{ path: 'foro', component: ForoComponent, canActivate: [ AuthGuard ]},
{ path: 'agregar/:id', component: AgregarComponent, canActivate: [ AuthGuard ]},
{ path: 'actualizar/:id', component: ActualizarComponent, canActivate: [ AuthGuard ]},
{ path: 'actualizar2/:id', component: Actualizar2Component, canActivate: [ AuthGuard ]},
{ path: 'agregar2/:id', component: Agregar2Component, canActivate: [ AuthGuard ]},
{ path: 'about', component: AboutComponent},
{ path: '**', pathMatch: 'full', redirectTo: 'home' }

];

@NgModule({
    imports: [
        RouterModule.forRoot( APP_ROUTES , {useHash: true})
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule{}
