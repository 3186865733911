import { Component, OnInit } from '@angular/core';
import { RutinaModel } from '../../models/rutina.model';
import { NgForm } from '@angular/forms';
import { RutinasSService } from '../../services/rutinas-s.service';

import { AngularFireStorage } from '@angular/fire/storage';
import 'firebase/storage';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-actualizar',
  templateUrl: './actualizar.component.html',
  styleUrls: ['./actualizar.component.css']
})
export class ActualizarComponent implements OnInit {

  rutina = new RutinaModel();

  uploadProgress: Observable<number>;

  uploadURL: Observable<string>;
  downloadURL = '';



  upload(event) {
    const file = event.target.files[0];
    const filepath = `${this.rutina.imagen}`;
    const fileRef = this.storage.ref(filepath);
    const task = this.storage.upload(filepath, file);


    task.snapshotChanges().pipe(
    finalize(() => {
    fileRef.getDownloadURL().subscribe(urlImage => {
    this.downloadURL = urlImage;
    console.log('URL_IMAGE', urlImage);
    });
    })
    ).subscribe();
    }

  constructor(private activatedRoute: ActivatedRoute, private rutinasService: RutinasSService, private storage: AngularFireStorage) { }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.rutina.id = id;
  }

  guardar( form: NgForm){

    if ( form.invalid){
      console.log('Formulario no válido');
      return;
    }

    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      icon: 'info',
      allowOutsideClick: false
    });
    Swal.showLoading();

    let peticion: Observable<any>;

    peticion =  this.rutinasService.actualizarRutina( this.rutina );

    peticion.subscribe( resp => {

      Swal.fire({
        title: this.rutina.nombre,
        text: 'Se actualizó correctamente',
        icon: 'success'
      });

    });

  }

}
