<div class="col-md-8">
    <h1 class="animated fadeIn fast">Registrarse <small>en la web</small></h1>
</div>
<hr>
<!-- The core Firebase JS SDK is always required and must be listed first -->
<script src="https://www.gstatic.com/firebasejs/7.14.5/firebase-app.js"></script>

<!-- TODO: Add SDKs for Firebase products that you want to use
     https://firebase.google.com/docs/web/setup#available-libraries -->
<script src="https://www.gstatic.com/firebasejs/7.14.5/firebase-analytics.js"></script>
<div class="limiter animated fadeIn">
    <div class="container-login100">
        <div class="wrap-login100 p-t-50 p-b-90">
            <form (ngSubmit)="onSubmit(f)" #f="ngForm" class="login100-form validate-form flex-sb flex-w">

                <span class="login100-form-title p-b-51">
                        Crear nueva cuenta
                    </span>

                <span *ngIf="f.submitted && f.controls['email'].errors" class="text-danger animated fadeIn">El correo es obligatorio / formato de correo inválido</span>
                <div class="wrap-input100 m-b-16">
                    <input class="input100" type="email" name="email" [(ngModel)]="usuario.email" required email placeholder="Email">

                    <span class="focus-input100"></span>
                </div>

                <span *ngIf="f.submitted && f.controls['nombre'].errors" class="text-danger">El nombre debe tener mínimo 2 caracteres</span>
                <div class="wrap-input100 m-b-16">
                    <input class="input100" type="text" name="nombre" required minlength="2" [(ngModel)]="usuario.nombre" placeholder="Nombre y apellidos">

                    <span class="focus-input100"></span>
                </div>

                <span *ngIf="f.submitted && f.controls['password'].errors" class="text-danger">La contraseña debe tener mínimo 6 caracteres</span>
                <div class="wrap-input100 m-b-16" data-validate="Password is required">
                    <input class="input100" type="password" name="password" required minlength="6" [(ngModel)]="usuario.password" placeholder="Password">
                    <span class="focus-input100"></span>
                </div>

                <div class="flex-sb-m w-full p-t-3 p-b-24">
                    <div class="contact100-form-checkbox">
                        <input [(ngModel)]="recordarme"
                        class="input-checkbox100" id="ckb1" type="checkbox" name="remember-me">
                        <label class="label-checkbox100" for="ckb1">
                                Recordar mi usuario
                            </label>
                    </div>

                    <div>
                        <a routerLink="/login" class="txt1">
                                ¿Ya tienes cuenta? / Ingresar
                            </a>
                    </div>
                </div>

                <div class="container-login100-form-btn m-t-17">
                    <button class="login100-form-btn" type="submit">
                            Crear cuenta
                        </button>
                </div>

            </form>
        </div>
    </div>
</div>

<script>
    // Your web app's Firebase configuration
    var firebaseConfig = {
      apiKey: "AIzaSyCFNHNk_u8r2wymzdjAFAZsPUEIL8cTl1Y",
      authDomain: "basededatosghm.firebaseapp.com",
      databaseURL: "https://basededatosghm.firebaseio.com",
      projectId: "basededatosghm",
      storageBucket: "basededatosghm.appspot.com",
      messagingSenderId: "165946587833",
      appId: "1:165946587833:web:c153c573739d1e95bd7968",
      measurementId: "G-HLDDJLE95W"
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
  </script>