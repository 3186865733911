<footer class="navbar navbar-expand-lg navbar-dark animated fadeIn ">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-md-6 text-left">
                <h6>CONTACTO:</h6>

                <ul>
                    <li>C/ de la piruleta, 42, Narnia</li>
                    <li>Madrid.</li>
                    <li>Teléfonos: 124816326 – 139278124.</li>
                    <li>Correo electrónico: gymhealthmanager@hogwarts.pina</li>

                </ul>
            </div>

            <div class="col-xs-12 col-md-6 ">
                <h6>ENCUENTRANOS EN LAS REDES:</h6>
                <div class="collapse navbar-collapse">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item">
                            <a class="nav-link" href="https://www.facebook.com/"><img src="assets/img/face.png" width="70px" height="70px"></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://twitter.com/"><img src="assets/img/twi.png" width="70px" height="70px"></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://www.instagram.com/"><img src="assets/img/insta.png" width="70px" height="70px"></a>
                        </li>
                    </ul>

                </div>
            </div>
            <div class="row">

                <div class="col-md-12 text-right">
                    <p class="rk-footer__text rk-footer__copy "> <span class="ae-u-bold">© </span><span class="ae-u-bolder">{{ anio }} GymHealthManager </span>All Right Reserved.</p>
                </div>

            </div>
        </div>

    </div>


</footer>