import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import { AngularFireStorage } from '@angular/fire/storage';
import 'firebase/storage';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/storage';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './guards/auth.guard';


// Rutas
import { AppRoutingModule } from './app-routing.module';

// Servicios
// Componentes
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { RutinasComponent } from './components/rutinas/rutinas.component';
import { AboutComponent } from './components/about/about.component';
import { DietasComponent } from './components/dietas/dietas.component';
import { ForoComponent } from './components/foro/foro.component';
import { RutinaComponent } from './components/rutina/rutina.component';
import { BuscadorComponent } from './components/buscador/buscador.component';
import { DietaComponent } from './components/dieta/dieta.component';
import { BuscadordComponent } from './components/buscadord/buscadord.component';
import { LoginComponent } from './components/login/login.component';
import { RegistroComponent } from './components/registro/registro.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { AgregarComponent } from './gestiones/agregar/agregar.component';
import { Agregar2Component } from './gestiones/agregar2/agregar2.component';
import { environment } from '../environments/environment';
import { ActualizarComponent } from './gestiones/actualizar/actualizar.component';
import { Actualizar2Component } from './gestiones/actualizar2/actualizar2.component';
import { PerfilComponent } from './components/perfil/perfil.component';




@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    HeaderComponent,
    RutinasComponent,
    AboutComponent,
    DietasComponent,
    ForoComponent,
    RutinaComponent,
    BuscadorComponent,
    DietaComponent,
    BuscadordComponent,
    LoginComponent,
    RegistroComponent,
    FooterComponent,
    AgregarComponent,
    Agregar2Component,
    ActualizarComponent,
    Actualizar2Component,
    PerfilComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
AngularFireModule.initializeApp(environment.firebase),
AngularFireStorageModule,
ReactiveFormsModule,
CommonModule

  ],
  providers: [
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

