import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RutinasSService } from '../../services/rutinas-s.service';
import { RutinaModel } from 'src/app/models/rutina.model';
import { AuthService } from '../../services/auth.service';
import { PerfilesSService } from '../../services/perfiles-s.service';
import { PerfilModel } from '../../models/perfil.model';



@Component({
  selector: 'app-rutinas',
  templateUrl: './rutinas.component.html',

})
export class RutinasComponent implements OnInit {
  rutinas: RutinaModel [] = [];
  perfiles : PerfilModel [] = [];
  rol : string;
 public isAdmin: boolean = false;
  constructor(private RutinasSService: RutinasSService, private _perfilesService: PerfilesSService, private router: Router, private auth: AuthService) {



   }

  ngOnInit(): void {
    this.isAdmin = this.auth.isAdmin();
    this.RutinasSService.getRutinas().toPromise().then((data: RutinaModel[]) => {
    this.rutinas = data;
    this.rutinas.forEach(element => {
      element.imagen="https://firebasestorage.googleapis.com/v0/b/basededatosghm.appspot.com/o/C%3A%5Cfakepath%5C"+element.imagen.substr(12)+"?alt=media&token=f6af9e5c-6c5e-467c-8c72-0ec3227463ae";
    });
  }, err => {
    console.error(err);
  });
  this._perfilesService.getPerfiles().toPromise().then((data: PerfilModel[]) => {
    this.perfiles = data;
    this.perfiles.forEach(element => {
      if(element.email == localStorage.getItem('id')){
        this.rol=element.rol;
      }
    });
  }, err => {
    console.error(err);
  });
  }

buscarRutina(termino: string){
  this.router.navigate(['/buscar', termino]);
}

}
