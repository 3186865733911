import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DietaModel } from '../models/dieta.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DietasSService {

  private url = 'https://basededatosghm.firebaseio.com';

  constructor( private http: HttpClient) { }

  crearDieta( dieta: DietaModel ){

    return this.http.post( `${ this.url }/dietas.json`, dieta)
    .pipe(
      map( (resp: any) => {
        dieta.id = resp.name;
        return dieta;
      })
    );

  }

  actualizarDieta( dieta: DietaModel ){

    const dietaTemp = {
      ...dieta
    };

    delete dietaTemp.id;
    console.log(dieta);
    console.log(dietaTemp);

    return this.http.put(`${ this.url }/dietas/${ dieta.id }.json`, dietaTemp);
  }

  getDietas() {
    return this.http.get(`${ this.url }/dietas.json`)
    .pipe(
      map(this.crearArreglo)
    );
  }

  getDieta(id: string) {

    return this.http.get(`${ this.url }/dietas/${id}.json`)
  }
  borrarDieta(id : string){
    return this.http.delete(`${ this.url }/dietas/${id}.json`)
  }

  buscarDietas(termino: string){
    let dietas: DietaModel[] = [];
    let dietasArr: DietaModel[] = [];
    this.getDietas().toPromise().then((data: DietaModel[]) => {
      dietas = data;
      dietas.forEach(element => {
        element.imagen="https://firebasestorage.googleapis.com/v0/b/basededatosghm.appspot.com/o/C%3A%5Cfakepath%5C"+element.imagen.substr(12)+"?alt=media&token=f6af9e5c-6c5e-467c-8c72-0ec3227463ae";
      });
      termino = termino.toLowerCase();
      for (let dieta of dietas){
    let nombre= dieta.nombre.toLowerCase();
    if( nombre.indexOf(termino)>=0){
      dietasArr.push(dieta);
    }
    }
    console.log(dietasArr);
    }, err => {
      console.error(err);
    });
  
    return dietasArr;
  }

  private crearArreglo( dietasObj: object ){

    const dietas: DietaModel[] = [];
    console.log(dietasObj);

    if ( dietasObj === null ) { return []; }

    Object.keys( dietasObj ).forEach( key => {

      const dieta: DietaModel = dietasObj[key];
      dieta.id = key;
      dietas.push( dieta );


    });

    return dietas;

  }
}
