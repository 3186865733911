

export class DietaModel{


    id: string;
    nombre: string;
    descripcion: string;
    imagen: string;

    constructor(){
    }
    }
